import { memo, useEffect, useRef, useState } from 'react';
import { cn } from '@divlab/divanui';
import { CSSTransition } from 'react-transition-group';

import useMedias from '@Hooks/useMedias';
import useScrollPosition from '@Hooks/useScrollPosition';
import * as CatalogFiltrator from '@Stores/CatalogFiltrator';
import useTranslation from '@Queries/useTranslation';
import FilterSelectedValues from '@Components/FilterSelectedValues';
import Labels from '../common/Labels';
import QuickFilters from '../QuickFilters';
import styles from './StickyFilters.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { FilterView } from '@Types/Filters';

export interface StickyFiltersProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  count?: number;
  visible: boolean;
  isLoadingPage?: boolean;
  filtersOffsetTop?: number;
  onOpen?: (e: MouseEvent, id: string, filterView: FilterView) => void;
}

const StickyFilters: FC<StickyFiltersProps> = (props) => {
  const {
    className,
    count,
    visible,
    isLoadingPage,
    filtersOffsetTop = 0,
    onOpen,
    ...restProps
  } = props;
  const { t } = useTranslation();
  const { isOnlyDesktop, isMobile } = useMedias();
  const filtrator = CatalogFiltrator.useFiltrator();
  const priorityFilters = CatalogFiltrator.usePriorityFilters();
  const appliedValues = CatalogFiltrator.useAppliedValues();
  const [open, setOpen] = useState(false);
  const refWrapper = useRef<HTMLDivElement>();

  useScrollPosition(({ current, previous }) => {
    const isUp = previous.y >= current.y;

    if (!visible) return;

    if (refWrapper.current && current.y <= filtersOffsetTop) {
      refWrapper.current.style.display = 'none';
    }

    if (isUp && current.y !== 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  });

  useEffect(() => {
    setOpen(visible && !isLoadingPage);
  }, [visible, isLoadingPage]);

  return (
    <CSSTransition
      timeout={400}
      classNames={{
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exitActive: styles.exitActive,
      }}
      unmountOnExit
      in={open}
    >
      <div {...restProps} className={cn(styles.wrapper, className)} ref={refWrapper}>
        <div className={styles.container}>
          <div className={styles.filtersWrapper}>
            <div className={styles.actions}>
              {filtrator.filters && (
                <QuickFilters
                  priorityFilters={priorityFilters}
                  hiddenPriorityFilters={isMobile}
                  view='sticky'
                  onOpen={onOpen}
                />
              )}
              {typeof count === 'number' && isOnlyDesktop && (
                <div className={styles.count}>
                  {t('ui.filters.found')}
                  <span className='ProductRange'>{count}</span>
                </div>
              )}
            </div>
            {filtrator.sort.length > 0 && (
              <div className={styles.wrapperLabels}>
                <Labels filterView='sticky' />
              </div>
            )}
          </div>

          {appliedValues.length > 0 && (
            <FilterSelectedValues
              className={styles.selectedValues}
              values={appliedValues}
              filtratorId='catalog'
            />
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

export default memo(StickyFilters);
